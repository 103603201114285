import '../../App.scss';
import React from "react";
import Banner from './main-banner/Banner.js';
import Navbar from './header/Navbar.js';




function Landing() {


  return (
    <>
    <Navbar />
        <Banner />
      
    </>
  );
}

export default Landing;
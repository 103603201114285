import React, { useEffect, useState } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useWeb3React } from "@web3-react/core";
import { useHistory } from "react-router-dom";

const Banner = () => {
  const history = useHistory();
  const { account } = useWeb3React();
  const { login, logout } = useAuth();

  const connectMetamaskSignUp = async () => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId");
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      login("injected");
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
    }
  };

  const trustWalletSignUp = async () => {
    // console.log("call")
    if (account) {
      await logout("walletconnect");
    } else {
      login("walletconnect");
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
    }
  };

  useEffect(() => {
    if (account) {
      // history.push("/refund");
      window.location.replace("/refund")
    }
  }, [account]);

  return (
    <>
      <section className="main-banner">
        <div className="custom-container">
          <div className="main-heading">
            <img src="\assets\gems-logo.svg" alt="img" className="img-fluid" />
            <h6>Gems Refund</h6>
            <p>Connect your wallet to get your refund.</p>
          </div>
          <div className="bottom-content">
            <h5>Connect the wallet that you used to buy $GEMS</h5>
            <div className="twice-btns">
              <Link
                onClick={() => {
                  connectMetamaskSignUp();
                }}
                className="metamask-desktop"
              >
                <p>Desktop </p>
                <img
                  src="\assets\metamask.svg"
                  alt="img"
                  className="img-fluid"
                />
                <h6>MetaMask</h6>
              </Link>
              <Link
                onClick={() => {
                  trustWalletSignUp();
                }}
              >
                <p>Mobile </p>
                <img src="\assets\trust.svg" alt="img" className="img-fluid" />
                <h6>WalletConnect</h6>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;

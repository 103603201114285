import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "./useWeb3";
import environment from "../utils/Environment";
import { refundContract } from "../utils/contractHelpers";
import axios from "axios";

export const RefundFunds = () => {
  const { account, chainId } = useWeb3React();
  const web3 = useWeb3();
  

  const RefundI = useCallback(
    async (address, buyingAmount, leaves, callback) => {
  const stakingAddress = environment.refund;
  const contract = refundContract(stakingAddress, web3);
      console.log("quantity payload", contract,account,web3,stakingAddress);
      try {
        let gasPrice = await web3.eth.getGasPrice();
        gasPrice = parseInt(gasPrice) + 3000000000;
        const gas = await contract.methods
          .refund(address, buyingAmount, leaves)
          .estimateGas({ from: account });
        const claimed = await contract.methods
          .refund(address, buyingAmount, leaves)
          .send({ from: account, gas: gas, gasPrice: gasPrice })
          .on("transactionHash", (tx) => {
            console.log("tx:::", tx);
            callback();
            return tx;
          });
        //   .on("confirmation", (confirmationNumber, receipt) => {
        //     console.log("Transaction confirmed:", receipt);
        //     if (confirmationNumber === 1) {
        //       callback();
        //       return receipt;
        //     }
        //   });

        //   .on("transactionHash", (tx) => {
        //     console.log("tx:::", tx);
        //     // callback();
        //     // return tx;
        //   })
        // return claimed;
      } catch (e) {
        console.log("errr", e);
        throw e;
      }
    },
    [web3]
  );

  return { RefundI: RefundI };
};

export default RefundFunds;

import React from "react";
import "./navbar.scss";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
import { useHistory } from "react-router-dom";

const Navbar = () => {
  const { account } = useWeb3React();
  const { logout } = useAuth();
  const history = useHistory();

  const Logout = async () => {
    let locaWallet = localStorage.getItem("connectorId");
    await logout(locaWallet);
    localStorage.clear();
    history.push("/");
    window.location.reload();
  };

  return (
    <>
      <section className="main-navbar">
        <div className="custom-container p-0">
          <div className="parent">
            <a href="/">
              <img src="\logo.svg" alt="img" className="img-fluid" />
            </a>
            {account && (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => Logout()}
                className="btn-disconnect"
              >
                {account ? "Disconnect" : "Connect"}
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;

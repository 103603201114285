import React from 'react'
import "./refund.scss"
import Navbar from '../header/Navbar'


const Alreadyrefund = () => {
   
    return (
        <>
            <Navbar />
            <section className="refund alrady-refund">
                <div className="custom-container">
                    <div className="refund-box">
                        <h6>You have already claimed your refund</h6>
                        {/* <h6>You are not eligible for a refund</h6> */}
                        <a className='disable'>Get a refund</a>
                    </div>
                </div>
            </section>

          
        </>
    )
}

export default Alreadyrefund

import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Refund from './components/landing/Refund/Refund.js';
// import Test from './components/test.js';
import Alreadyrefund from './components/landing/Refund/Alreadyrefund.js';
import useEagerConnect from "./hooks/useEagerConnect"

function App() {
  useEagerConnect();
  return (
    <>
        <ToastContainer style={{ fontSize: 20 }} />
          <Router>
            <Switch>
              <Route exact path='/' component={Landing} />
              <Route exact path='/refund' component={Refund} />
              <Route exact path='/alreadyrefund' component={Alreadyrefund} />
            </Switch>
          </Router>
    </>
  );
}

export default App;

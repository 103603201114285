import React from "react";
import "./refund.scss";
import Navbar from "../header/Navbar";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import keccak256 from "keccak256";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { MerkleTree } from "merkletreejs";
import Loader from "../../../hooks/loader";
import RefundFunds from "../../../hooks/RefundFunds";
import Web3 from "web3";
import IsRefund from "../../../hooks/isRefunded";
import NewCSV from "../../newcsv2.json"

const Refund = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const { account } = useWeb3React();
  const [nftLeaves, setNftLeaves] = useState(null);
  const [myData, setMyData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { RefundI } = RefundFunds();
  const { IsRefunded } = IsRefund();
  const [tree, setTree] = useState(null);
  const [refu, setRefu] = useState(false);

  useEffect(() => {
    if (account) {
      checkRef(account);
    }
  }, [account]);

  console.log("acc", account, myData)
  const checkRef = async () => {
    setLoader(true);
    const res = await IsRefunded(account);
    console.log(res, 'res');
    setRefu(res);
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    if (ethers && account) {
      // console.log()
      let addr = NewCSV;
      let muobj = addr?.find((i) => {
        console.log("iiiiiiiiii", i)
        return i?.by.toLowerCase() == account.toLowerCase()
      });

      console.log("sososoos", muobj)
      setMyData(muobj);

      const leaves = addr?.map((x) => {
        return ethers?.utils?.solidityKeccak256(
          ["address", "address[]", "uint256[]"],
          [
            x.by,
            x.tokens,
            x.amounts,
          ]
        );
      });

      const tree1 = new MerkleTree(leaves, keccak256, { sort: true });
      //   console.log("tree1", tree1);
      setTree(tree1);
      const proofs = leaves.map((leaf) => tree1.getHexProof(leaf));
      const root = tree1.getHexRoot();
      if(tree1){
        setLoader(false)
      }

      // Output the data to console or use set state functions here
      // console.log("tree", tree1);
      // console.log("leavesnode", proofs);
      // console.log("root", root);
    }
  }, [ethers, account]);

  const StartRefund = async () => {
    try {
      let address = null;
      let amounts = null;
      setLoader(true);
      let leave = null;

      address = myData.tokens;
      amounts = myData.amounts;
      leave = ethers.utils.solidityKeccak256(
        ["address", "address[]", "uint256[]"],
        [
          myData.by,
          myData.tokens,
          myData.amounts,
        ]
      );

      const proofs = tree.getHexProof(leave);
      const res = await RefundI(
        address,
        amounts,
        proofs,
        () => {
          setLoader(false);
          setRefu(true);
          handleShow();
        }
      );
      if (res) {
        // console.log("rs", res);
        // if (res?.code === 4001) {
        //   handleShow1();
        // } else {
        // handleShow();
        // setRefu(true);
        // // }
        // setLoader(false);
      }
    } catch (err) {
      console.log("err:::::::", err);
      handleShow1();
      setLoader(false);
    }
  };
  // console.log("shahzeb", myData);
  return (
    <>
      {loader && <Loader />}
      <Navbar />
      <section className="refund">
        <div className="custom-container">
          <div className="refund-box">
            {!refu ? (
              <>
                 {
                myData?.length == 0 ? " " :(
                  <>
                    {myData?.amounts?.length > 0 ? (
                      <>
                        <h6>This is your total investment for buying $GEMS</h6>
                        {myData?.amounts ? (
                          <>
                            {myData?.amounts?.map((item, index) => {
                              return (
                                <h2>{myData?.tokens[index] == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE" ?
                                  <>
                                    {parseFloat(item) / 1000000000000000000 + " ETH"}
                                  </> : myData?.tokens[index] == "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48" ?
                                    <>
                                      {parseFloat(item) / 1000000 + " USDC"}
                                    </> :
                                    myData?.tokens[index] == "0xdAC17F958D2ee523a2206206994597C13D831ec7" ?
                                      <>
                                        {parseFloat(item) / 1000000 + " USDT"}
                                      </> : ''
                                }

                                </h2>
                              )
                            })}
                          </>
                        ) : (
                          <h5 style={{ textAlign: "center" }}>No refund found.</h5>
                        )}
                      </>
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 16,
                          marginBottom: 50,
                          color: "rgba(0, 0, 0, 1)",
                        }}
                      >
                        You are not eligible for a refund
                      </p>
                    )}
                  </>
            )}
              </>
           
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontSize: 16,
                  marginBottom: 50,
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                You have already claimed your refund
              </p>
            )}
            {/* onClick={handleShow} */}
            {myData?.amounts ? (
              <button
                className={refu ? "disable" : ""}
                disabled={refu}
                onClick={() => StartRefund()}
              >
                Get a refund
              </button>
            ) : (
              <button className={"disable"} disabled={true}>
                Get a refund
              </button>
            )}
          </div>
        </div>
      </section>

      <Modal
        className="success-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Refund Processed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inner-content">
            <img src="\assets\check.svg" alt="img" className="img-fluid" />
            <h6>
              Refund Processed Successfully <br />
              <>
                {myData?.amounts?.map((item, index) => {
                  return (
                    <h2>{myData?.tokens[index] == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE" ?
                      <>
                        {parseFloat(item) / 10000000000000000000 + " ETH"}
                      </> : myData?.tokens[index] == "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48" ?
                        <>
                          {parseFloat(item) / 1000000 + " USDC"}
                        </> :
                        myData?.tokens[index] == "0xdAC17F958D2ee523a2206206994597C13D831ec7" ?
                          <>
                            {parseFloat(item) / 1000000 + " USDT"}
                          </> : ''
                    }

                    </h2>
                  )
                })}
              </>

            </h6>
            <a
              onClick={() => {
                handleClose();
                // handleShow1();
              }}
            >
              Okay
            </a>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="success-modal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inner-content">
            <img src="\assets\cross.svg" alt="img" className="img-fluid" />
            <h6>Oops, something went wrong</h6>
            <a onClick={handleClose1}>Try Again</a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Refund;


